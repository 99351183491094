import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Link } from 'gatsby'

import { ContentHeader } from './style'

import ImageWebp from 'src/components/ImageWebp'

const IndiqueAmigosParaOInter = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentHeader className='d-flex justify-content-center justify-content-lg-between'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <ImageWebp
              arrayNumbers={[ 312, 336, 379, 456 ]}
              arrayNumbersHeight={[ 315, 338, 379, 460 ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/conta-digital-pessoa-fisica-indique-ganhe/image.webp'
              altDescription='Lettering do Indique e Ganhe Inter'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-48 lh-xl-50 text-grayscale--500 fw-600 d-block mb-3'>
              Indique amigos para o Inter e ganhe pontos Loop!
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500'>
              Indicando um amigo pro Inter, ambos ganham <b>200 pontos Loop</b> para trocar por benefícios!
            </p>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500 mb-0'>
              Quanto mais amigos indicar, mais você vai ganhar, podendo acumular até 40.000 (quarenta mil) pontos.
            </p>
            <div className='btn-div'>
              <Link
                to='/indique-e-ganhe/'
                title='Quero participar!'
                className='btn btn--lg btn-orange--extra rounded-2 fs-14 fw-600 text-none mw-100'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_07',
                    section_name: 'Indique amigos para o Inter e ganhe pontos Loop!',
                    element_action: 'click button',
                    element_name: 'Quero participar!',
                  })
                }}
              >
                Quero participar!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ContentHeader>
  )
}

export default IndiqueAmigosParaOInter
