import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const ContentHeader = styled.section`
  background-color: ${orange.extra};

  padding-top: 40px;
  padding-bottom: 40px;
  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  display: flex;
  min-height: 229px;
  align-items: center;
`
export const Cards = styled.div`
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
`

export const Card = styled.div`
  width: 100%;
  height: 78px;
  padding: 22px;
  border-radius: 8px;
  background-color: white;

  @media ${device.tablet} {
    padding: 16px 22px;
    width: 208px;
    min-height: 66px;
    max-height: 83px;
    height: auto;
  }
  @media ${device.desktopLG} {
    padding: 16px 22px;
    width: 250px;
    height: 70px;
  }
  @media ${device.desktopXL} {
    padding: 16px 26px;
    width: 268px;
    height: 78px;
  }
`
