import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ContentHeader = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  align-items: center;
  display: flex;
  min-height: 229px;

  a, button {
    height: 48px;
  }

  .btn-div {
    margin-top: 32px;
  }
`

export const Link = styled.a`
  max-width: 456px;
  height: 40px;
  margin-bottom: 30px;

  &:focus, &:hover {
    outline: none;
    text-decoration: none;
    color: #fff;
  }
`
