import React from 'react'

import { Card, NumBox, Line, Div } from './style'

type StepType = {
  description: string;
}

interface IStepper {
  data: StepType[];
}

const Stepper = ({ data }: IStepper) => {
  return (
    <Div>
      <Line>
        <div className='d-flex justify-content-xl-center'>
          <div>
            {data.map((step: StepType, index: number) => (
              <div className={`d-flex align-items-center card-container-${index + 1}`} key={step.description}>
                <NumBox className='d-flex justify-content-center align-items-center'>
                  <span className='fs-24 lh-30 fw-600 text-white'>{index + 1}</span>
                </NumBox>
                <Card className='d-flex align-items-center '>
                  <div>
                    <p className='fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-18 lh-xl-22 fw-400 text-grayscale--400 my-2'>{step.description}</p>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </Line>
    </Div>
  )
}

export default Stepper
