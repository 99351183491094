import styled from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
    background: #EBEBEB;
    min-height: 660px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    
  @media (min-width: ${breakpoints.md}) {
    min-height: 311px;
    align-items: center;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 350px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 480px;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    min-height: 550px;
  }

  img {
    position: absolute;
    top: 0;
    width: 100vw;

    @media (min-width: ${breakpoints.md}) {
      left: -70px;
      width: 66vw;
    }

    @media (min-width: ${breakpoints.lg}) {
      width: 50vw;
      left: 0;
    }
  }

  .margin-mobile {
    margin-bottom: 40px;

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 0;
    }
  }

`
export const Button = styled.a`
  height: 48px;
  width: 100%;
  border: none;
  border-radius: 8px;
  background: ${orange.base};
  color:${white}; ;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  &:focus, :hover {
    outline: none;
    color: ${white};
  }

`
